<template>
  <div
    v-if="!props.isCustomizable"
    class="mt-5 whitespace-pre-wrap break-words"
    :class="{ 'text-on-surface-variant': !props.description }"
  >
    {{ props.description ? props.description : $t('post-modal.sample-text') }}
  </div>
  <div v-else>
    <Description
      :description="props.description"
      enable-values
      @update:error-state="emit('update:error-state', $event)"
    />
  </div>
</template>
<script setup lang="ts">
import { Description } from '~/pages/_components/description';

type Props = {
  description: string;
  isCustomizable?: boolean;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  'update:error-state': [value: string];
}>();
</script>
